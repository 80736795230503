import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

interface IHeroPackReviewProps {
  quote: string;
  user: string;
  userImg: ImageDataLike;
}
const HeroPackReview = ({ quote, user, userImg }: IHeroPackReviewProps) => (
  <div className="flex items-start">
    <GatsbyImage
      className="h-9 w-9 rounded-full flex-shrink-0 inline-flex filter grayscale border border-gray-500"
      alt={user}
      image={getImage(userImg)!}
    />
    <div className="text-sm text-[#143352] font-normal ml-4 leading-4 font-['Source_Sans_Pro']">
      <span className="italic"> {`"${quote}"`}</span>
      {` - ${user}`}
    </div>
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      review {
        quote
        user
        userImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery query={query} render={(data) => <HeroPackReview {...data.productsYaml.review} />} />
);
