import { Link, PageProps } from "gatsby";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from "gatsby-plugin-image";
import Footer from "components/Footer";

export const pageQuery = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      guarantee {
        title
        description
      }
      results
      # how_to_use
    }
  }
`;

export default ({ data }: PageProps) => {
  const [show, setShow] = React.useState(false);
  const productRef = React.useRef(null);

  return (
    <main>
      <Masterclass setShow={setShow} />
      <section id="PRODUCT" ref={productRef}>
        <Product data={data} />
      </section>
      <Footer />
    </main>
  );
};

import ProductHero from "components/MiniVSL/ProductHero";
import ProductSeenIn from "components/MiniVSL/ProductSeenIn";
import ProductDetails from "components/MiniVSL/ProductDetails";
import ProductBenefits from "components/MiniVSL/ProductBenefits";
import Ingredients from "components/MiniVSL/Ingredients";
import Routine from "components/MiniVSL/Routine";
import Reviews from "components/MiniVSL/Reviews";
import FAQs from "components/MiniVSL/FAQ";
import OtherProducts from "components/MiniVSL/OtherProducts";
import Incentives from "components/MiniVSL/Incentives";

const Product = ({ data }) => {
  return (
    <>
      <div className="max-w-6xl mx-auto">
        <ProductHero />
        <div className="my-16">
          <ProductSeenIn />
        </div>
        <ProductDetails />
        <ProductBenefits />
      </div>
      {/* Planas Guarantee */}
      <section id="GUARANTEE" className="bg-[#143352] py-10">
        <div className="max-w-6xl mx-auto">
          <MoneyBackGuarantee {...data.productsYaml.guarantee} />
        </div>
      </section>
      <div className="max-w-6xl mx-auto">
        <Ingredients />
        <Routine />
      </div>
      {/* REVIEWS -------------------------------------------------------------------------------*/}
      <div className="bg-gray-100">
        <div className="max-w-6xl mx-auto py-24 ">
          <Reviews />
        </div>
      </div>
      <div className="bg-gray-50 pt-24 pb-24">
        <FAQs />
      </div>
      <div className="max-w-6xl mx-auto py-14 ">
        <OtherProducts />
      </div>

      {/* Incentives */}
      <div className="bg-blue-50">
        <div className="max-w-6xl mx-auto py-7 ">
          <Incentives />
        </div>
      </div>
    </>
  );
};

import MoneyBackGuarantee from "components/MoneyBackGuarantee";

// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------

import WistiaVideo from "components/WistiaVideo";
import { Wrapper } from "components/MasterclassFunnel";
import { Heading1 } from "components/MasterclassFunnel";

const Masterclass = ({ setShow }) => {
  React.useEffect(() => {
    // Get Wistia video handler
    window._wq = window._wq || []; // make sure window._wq is defined as an array
    window._wq.push({
      // push a matcher associated with callback function onto the queue
      id: "v6ah4ctlya",
      onReady: function (video: any) {
        // @TODO: change time to when the offer happens
        video.bind("crosstime", 20 * 60, function () {
          // setShowCTA(true);
        });

        // @TODO: set the analutics as % of video watched (with the seconds)
      },
    });
  }, []);

  return (
    <>
      <section id="HERO" className="relative">
        <Wrapper>
          <div id="HEADER" className="text-gray-300 flex justify-center items-cente -mt-10 mb-10">
            <h1 className="uppercase text-sm pt-3 font-['Copperplate_Gothic']">Dr. Jorge Planas</h1>
            <DrPlanasLogo className="h-10 w-10" />
          </div>
          <div id="HERO">
            <StaticImage
              alt="Dr Planas en despacho"
              src="../../static/masterclass-hero-bg.jpg"
              className="top-0 left-0 h-full w-full z-[-2] brightness-[0.2]"
              style={{ position: "absolute" }}
            />
            {/* <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-[#143352]" /> */}
            <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-black/50" />
            <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-[#1B4A93]/10" />

            <p
              className={
                `mb-3 text-red-600  text-center font-bold  tracking-wider animate-blinkingText` +
                ` text-[14px] leading-[20px] hidden md:block`
              }
            >
              <span className="w-[12px] h-[12px] rounded-full bg-red-600 mr-2 inline-block " />
              AVISO: NO CIERRES LA PAGINA <br className="md:hidden" />O PERDERAS TU PLAZA
            </p>

            <div className="mt-8 mb-4 rounded-full py-1 px-3 bg-black/60 text-white/80 font-medium  text-xl text-center max-w-max mx-auto capitalize">
              ATENCIÓN: todas las personas que deseen rejuvencer su piel
            </div>

            <Heading1 className="text-gray-100">
              El Método de 3 Pasos para Rejuvenecer La Piel de 10 a 15 Años en 90 Dias
            </Heading1>

            <ArrowSmDownIcon className="text-white mx-auto w-10 h-10 mt-3" />

            <div className="max-w-4xl mx-auto relative mt-6 flex-1 w-full shadow-[0_0_20px_rgba(29,74,255,0.7)] hover:shadow-none transition-all rounded-md overflow-hidden aspect-video">
              <WistiaVideo scriptCode="v6ah4ctlya" />
            </div>

            <a href="#PRODUCT" className="md:hidden">
              <button
                className={
                  `bg-[rgb(29,74,255)] py-2 px-14 rounded-full text-white font-medium transition-all border border-[rgb(29,74,255)] w-full md:w-auto mt-5 ` +
                  ` hover:bg-transparent text-lg`
                }
              >
                Empezar
              </button>
            </a>

            {/* <CTAButton setShow={setShow} /> */}
          </div>
        </Wrapper>
      </section>
      <section id="HERO-CTA" className="relative bg-black hidden md:block">
        <Wrapper className="py-3 md:py-12">
          <div className="max-w-5xl mx-auto">
            <div className="w-full border-[3px] border-[rgb(29,74,255)] md:grid grid-cols-2 gap-4 items-center py-8 px-12 md:px-16 rounded-md">
              <div>
                <p className="text-white font-semibold text-3xl tracking-wide text-center md:text-left">
                  Rejuvence tu piel hoy
                </p>
              </div>
              <div className="flex items-center w-full">
                <div className="hidden md:block md:w-7/12">
                  <hr className=" bg-[rgb(29,74,255)] w-full h-1 border-[rgb(29,74,255)]" />
                </div>
                <div className="w-full md:w-5/12 mt-5 md:mt-0">
                  <a href="#PRODUCT">
                    <button
                      className={
                        `bg-[rgb(29,74,255)] py-2 px-14 rounded-full text-white font-medium transition-all border border-[rgb(29,74,255)] w-full md:w-auto ` +
                        ` hover:bg-transparent`
                      }
                    >
                      Empezar
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
    </>
  );
};

const CTAButton = ({ setShow }) => (
  <div className="relative mx-auto mt-4 mb-10 hover:scale-105 transition-scale duration-[600ms] max-w-full md:w-max px-5">
    <div className="relative rounded-md overflow-hidden">
      <a href="#PRODUCT">
        <button
          onClick={() => setShow(true)}
          className="text-white bg-[rgb(29,74,255)] px-7 py-5 text-center mx-auto rounded-md"
        >
          <p className="text-xl md:text-3xl font-extrabold md:font-bold uppercase">
            Reclama tu descuento de 66€
          </p>
          <p className="text-xs md:text-sm font-base uppercase mt-1">
            ¡Se rapido! A dia de hoy (15 de junio), casi todos los paquetes de esta oferta estan
            agotados
          </p>
        </button>
      </a>
    </div>
  </div>
);

// -------------------------------------------------------------------------------------------------
// ICONS & SVGs
// -------------------------------------------------------------------------------------------------
const DrPlanasLogo = ({ className = "" }) => (
  <svg className={className} viewBox="0 0 528 768" fill="none">
    <path
      d="M7.5 263.5C59.8333 208.333 174.7 101.4 217.5 131C271 168 93 622.5 96.5 702C99.7355 775.491 200.39 475.971 317.097 374.188M317.097 374.188C326.639 365.866 336.288 358.866 346 353.5M317.097 374.188C314.567 438.767 320.755 488.587 330.5 498.5C347.123 515.409 362 340.5 346 353.5M317.097 374.188C322.095 246.592 361.127 61.3756 474 12.5C562 -25.6052 474 213 355.5 320C346 328.578 341.5 346.5 346 353.5M150 759.5C163 757 207.009 748.948 242 723.5C275 699.5 401 613 426.5 607M511 334.5L520.5 327"
      stroke="currentColor"
      strokeWidth="15"
      strokeLinecap="round"
    />
  </svg>
);

const ArrowSmDownIcon = ({ className = "" }) => (
  <svg
    className={` ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
  </svg>
);
