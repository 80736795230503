import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";
import SectionHeader from "components/SectionHeader";
import Ingredients from "components/Ingredients";

interface IIngredientesProps {
  header: {
    heading: string;
    subheading: string;
  };
  ingredients: {
    ingredient_list: Array<{ name: string; description: string }>;
    image: {
      image: ImageDataLike;
      alt: string;
    };
  };
}
const Ingredientes = ({ header, ingredients }: IIngredientesProps) => (
  <div className="gap-8 my-20">
    {/* <SectionHeader {...header} /> */}
    <h3 className="text-xs font-bold text-planasDark uppercase tracking-widest font-[Montserrat]">
      {header.subheading}
    </h3>
    <h1 className="text-2xl font-bold text-planasDark my-10 font-[Montserrat] text-[40px]">
      {header.heading}
    </h1>
    <Ingredients {...ingredients} />
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      ingredients {
        header {
          heading
          subheading
        }
        content {
          ingredient_list {
            action
            description
            name
          }
          image {
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alt
          }
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Ingredientes
        header={data.productsYaml.ingredients.header}
        ingredients={data.productsYaml.ingredients.content}
      />
    )}
  />
);
