import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

interface IProductDetailsProps {
  img: {
    aboutImageAlt: string;
    aboutImage: ImageDataLike;
  };
}
const AsSeenIn = () => {
  const [selectedQuote, setSelectedQuote] = React.useState(asSeenQuotes[0]);
  return (
    <>
      <div className="gap-3 md:gap-10 grid grid-cols-2 md:grid-cols-5 items-center justify-items-center">
        <div className="aspect-[167.9/63.1] h-9 flex items-center justify-center order-1">
          <HolaLogo />
        </div>
        <div className="aspect-[438/107] h-8 flex items-center justify-center order-2">
          <BazaarLogo />
        </div>
        <div className="aspect-[200/54] h-8 flex items-center justify-center order-4 md:order-3">
          <ForbesLogo />
        </div>
        <div className="aspect-[113/23] h-8 flex items-center justify-center order-5 md:order-4">
          <FueraDeSeriesLogo />
        </div>
        <div className="aspect-[528/385] h-12 flex items-center justify-center col-span-2 md:col-span-1 order-3 md:order-5">
          <DiezMinutosLogo />
        </div>
      </div>
      {/* The Box with all the logos */}
      {/* <div className="mx-auto gap-5 flex">
        <LogoBox2 Logo={BazaarLogo} setState={setSelectedQuote} loc={0} />
        <LogoBox2 Logo={ForbesLogo} setState={setSelectedQuote} loc={3} />

        <LogoBox2 Logo={HolaLogo} setState={setSelectedQuote} loc={1} />

        <LogoBox2 Logo={DiezMinutosLogo} setState={setSelectedQuote} loc={2} />

        <LogoBox2 Logo={FueraDeSeriesLogo} setState={setSelectedQuote} loc={4} />
      </div>

      {/* The Box with the selected quote*/}
      {/* <div className="text-planasDark mt-12">
        <QuoteIcon className="h-12 mx-auto" />
        <h3 className="text-center text-xl font-bold" style={{ fontFamily: "Montserrat" }}>
          {selectedQuote.name}
        </h3>
        <div className="relative text-center max-w-3xl mx-auto">
          <p className="text-lg italic absolute w-full" style={{ fontFamily: "Source Sans Pro" }}>
            {`"${selectedQuote.quote}"`}
          </p>
        </div>
      </div> */}
    </>
  );
};

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      aboutImageAlt
      aboutImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
// export default () => (
//   <StaticQuery query={query} render={(data) => <ProductDetails img={data.productsYaml} />} />
// );
export default AsSeenIn;

import {
  BazaarLogo,
  HolaLogo,
  DiezMinutosLogo,
  ForbesLogo,
  FueraDeSeriesLogo,
} from "components/Graphics";
import { QuoteIcon } from "components/Graphics";
export const asSeenQuotes = [
  {
    icon: BazaarLogo,
    name: "Harper's Bazaar",
    quote:
      "La línea perfecta para quienes quieren una piel radiante sin tener que pasar por quirófano.",
  },
  {
    icon: HolaLogo,
    name: "¡Hola!",
    quote: "Me sorprendió muchísimo lo rápido que empecé a notar los resultados.",
  },
  {
    icon: DiezMinutosLogo,
    name: "Diez Minutos",
    quote:
      "Otro de los problemas de la piel bajo la mascarilla es el acné. Busca productos que además de efecto antiarrugas sean antimicrobianos, antiinflamatorios, humectantes y reparadores. como el Serum Antiarrugas del Dr Planas. ",
  },
  {
    icon: ForbesLogo,
    name: "Forbes",
    quote:
      "Jorge Planas lidera una revolución en la cosmética de lujo de la mano de potentes ingredientes naturales.",
  },
  {
    icon: FueraDeSeriesLogo,
    name: "Fuera de Serie",
    quote:
      "Una terapia facial antiaging creada para ser fácil de usar y formar parte de la rutina de belleza diaria de todos aquellos que quieran cuidar su piel.",
  },
];

const LogoBox = ({
  Logo,
  loc,
  setState,
}: {
  Logo: React.ComponentProps<"svg">;
  loc: number;
  setState: React.Dispatch<
    React.SetStateAction<{
      icon: any;
      name: string;
      quote: string;
    }>
  >;
}) => {
  return (
    <div
      className="border border-black py-5 px-5 rounded-2xl justify-center text-center hover:shadow-2xl transition-all"
      onMouseOver={() => setState(asSeenQuotes[loc])}
    >
      <Logo />
    </div>
  );
};

const LogoBox2 = ({
  Logo,
  loc,
  setState,
}: {
  Logo: React.ComponentProps<"svg">;
  loc: number;
  setState: React.Dispatch<
    React.SetStateAction<{
      icon: any;
      name: string;
      quote: string;
    }>
  >;
}) => {
  return (
    <div
      className="border border-black py-5 px-5 rounded-2xl justify-center text-center hover:shadow-2xl transition-all"
      onMouseOver={() => setState(asSeenQuotes[loc])}
    >
      <Logo className="h-14 mx-auto" />
    </div>
  );
};
