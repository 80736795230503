import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import Routine from "components/Routine";

interface IRoutineProps {
  data: {
    header: {
      heading: string;
      subheading: string;
    };
    content: {
      video: {
        scriptCode: string;
      };
      routine_list: {
        title: string;
        description: string;
      }[];
    };
  };
}
const RoutineWithData = ({ data }: IRoutineProps) => <Routine {...data} />;

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      routine {
        header {
          heading
          subheading
        }
        content {
          video {
            scriptCode
          }
          routine_list {
            title
            description
          }
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => <RoutineWithData data={data.productsYaml.routine} />}
  />
);
