import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import ReviewStars from "components/ReviewStars";

interface IHeroReviewStarsProps {
  rating: number;
  reviews: number;
}
const HeroReviewStars = ({ rating, reviews }: IHeroReviewStarsProps) => (
  <div className="flex items-center">
    <ReviewStars stars={rating} size={5} />
    <p
      className="ml-2 text-xs italic text-planasDark"
      style={{ fontFamily: "Montserrat" }}
    >{`(${reviews} reseñas)`}</p>
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      rating
      reviews
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <HeroReviewStars rating={data.productsYaml.rating} reviews={data.productsYaml.reviews} />
    )}
  />
);
