import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

interface IProductDetailsProps {
  img: {
    aboutImageAlt: string;
    aboutImage: ImageDataLike;
  };
}
const ProductDetails = ({ img }: IProductDetailsProps) => (
  <div className="grid sm:grid-cols-10 gap-8 my-20 mx-5 sm:mx-0">
    <div className="sm:col-span-6">
      <h1 className="text-2xl font-bold text-planasDark font-[Montserrat]">
        La Solución Más Eficaz para el Envejecimiento
      </h1>
      <div className="text-base font-normal text-planasDark my-5 space-y-3 opacity-80 font-['Source_Sans_Pro'] text-[17px]">
        <p>
          Nuestra linea cosmética está clínicamente probado para ayudar a reducir los signos de
          envejecimiento, eliminando arrugas, surcos, y reponiendo el volumen natural de la piel.
        </p>
        <p>Nuestros clientes notan cambios visibles en dos días.</p>
        <p>
          Esto es posible gracias a uno de nuestros ingredientes claves, el Calendula Marigold, un
          activo único, con estudios clínicos, y patentado por el Doctor Jorge Planas que dedicó más
          de 10 años a investigarlo.
        </p>
        <p>
          El Calendula Marigold se compone de extracto de Caléndula fotoestimulada, multiplicando
          por 6 los beneficios naturales de la planta. Como son su acción antiinflamatoria,
          cicatrizante, antimicrobiana y antiséptica.
        </p>
        <p>
          El Doctor Jorge Planas lleva usando este exclusivo extracto en sus pacientes con
          resultados espectaculares, incluso en pieles con alteraciones severas como psoriasis e
          irritaciones.
        </p>
      </div>
    </div>
    <div className="sm:ml-5 sm:col-span-4 ">
      <GatsbyImage
        className="aspect-square"
        alt={img.aboutImageAlt}
        image={getImage(img.aboutImage)!}
      />
    </div>
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      aboutImageAlt
      aboutImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery query={query} render={(data) => <ProductDetails img={data.productsYaml} />} />
);
