import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import HeroReviewStars from "components/MiniVSL/ProductHero/HeroReviewStars";
import HeroPackItems from "components/MiniVSL/ProductHero/HeroPackItems";
import HeroPackReview from "components/MiniVSL/ProductHero/HeroPackReview";
import HeroBuyNow from "components/MiniVSL/ProductHero/HeroBuyNow";
import HeroBuyIncentives from "components/MiniVSL/ProductHero/HeroBuyIncentives";

interface IHeroProductDetailsProps {
  name: string;
  description: string;
}
const HeroProductDetails = ({ name, description }: IHeroProductDetailsProps) => {
  return (
    <>
      <h1
        aria-label="product-name"
        className="uppercase text-[27px] leading-[34px] text-[#2c2c2c] font-[Montserrat] font-bold"
        children={name}
      />
      <div className="mt-1 mb-5">
        <HeroReviewStars />
      </div>
      <p
        aria-label="product-description"
        className="my-5 text-base font-normal text-[#143352] font-['Source_Sans_Pro']"
        children={description}
      />
      <HeroPackItems />
      <div className="py-8">
        <HeroPackReview />
      </div>
      <HeroBuyNow />
      <HeroBuyIncentives />
    </>
  );
};

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      name
      description
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <HeroProductDetails
        name={data.productsYaml.name}
        description={data.productsYaml.description}
      />
    )}
  />
);
