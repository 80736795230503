import * as React from "react";
import { ShieldCheckIcon, LockClosedIcon } from "@heroicons/react/solid";
import { LogoDhl } from "components/Graphics";

const HeroBuyIncentives = () => (
  <div className="flex space-x-2 text-[0.65rem] text-black/50 text-center tracking-wide mt-4">
    <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1">
      <LockClosedIcon className="w-full h-3.5" />
      <p children="Pago Seguro" />
    </div>

    <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1">
      <LogoDhl className="w-full h-3.5" />
      <p children="Envio VIP Gratis" />
    </div>

    <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1">
      <ShieldCheckIcon className="w-full h-3.5" />
      <p children="Devolución de Dinero" />
    </div>
  </div>
);

export default HeroBuyIncentives;
