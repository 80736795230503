import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import { StoreContext } from "context/StoreContext";
import { formatPrice } from "utils/formatPrice";
import { ThreeDotsLoader } from "components/Graphics";

interface IHeroBuyNowProps {
  product: {
    available_for_sale: boolean;
    currency_code: string;
    price: number;
    variantStorefrontId: string;
  };
}
const HeroBuyNow = ({ product }: IHeroBuyNowProps) => {
  const { buyNow, loading } = React.useContext(StoreContext);
  const [quantity, setQuantity] = React.useState(1);

  return (
    <div className="my-5 overflow-visible">
      {product.available_for_sale ? (
        <>
          <div className="flex items-center my-5">
            Cantidad:
            <div className="flex items-center ml-4">
              <button
                className="bg-gray-100 rounded-full"
                onClick={() => setQuantity(Math.max(1, quantity - 1))}
              >
                <MinusIcon className="w-4 h-4 m-2" />
              </button>
              <p className="mx-3">{quantity}</p>
              <button
                className="bg-gray-100 rounded-full"
                onClick={() => setQuantity(quantity + 1)}
              >
                <PlusIcon className="w-4 h-4 m-2" />
              </button>
            </div>
            <p className="w-full text-right tracking-wider text-2xl font-medium">
              {formatPrice(product.currency_code, Number(product.price))}{" "}
              <span className="font-normal line-through">
                {formatPrice(product.currency_code, Number(205))}
              </span>
            </p>
          </div>
          <button
            className="my-1 py-3 w-full text-xl text-white font-medium font-[Montserrat] top-0 bg-[#1d4aff] hover:brightness-[0.85] border-2 border-transparent hover:border-[#143352]/50 hover:shadow-xl transition-all"
            children={
              !loading ? (
                <p className="">Empezar Ya</p>
              ) : (
                <div className="relative text-transparent">
                  Añadir a la cesta {/* Here so that button doesn't shrink  */}
                  <ThreeDotsLoader className="w-10 h-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                </div>
              )
            }
            onClick={() => buyNow(product.variantStorefrontId, quantity)}
          />
        </>
      ) : (
        <button
          disabled
          className="my-1 text-xl w-full py-3 font-medium font-[Montserrat] top-0 border-2 border-transparent border-[#EDDA8E]"
          children="Agotado"
        />
      )}
    </div>
  );
};

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging-masterclass" }) {
      available_for_sale
      currency_code
      price
      variantStorefrontId
    }
  }
`;
export default () => (
  <StaticQuery query={query} render={(data) => <HeroBuyNow product={data.productsYaml} />} />
);
