import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GiftIcon } from "@heroicons/react/outline";

interface IHeroPackItemsProps {
  included: Array<{
    name: string;
    size: string;
    isGift: boolean;
  }>;
}
const HeroPackItems = ({ included }: IHeroPackItemsProps) => (
  <div className="my-5">
    <ul className="list-disc list-inside text-base text-[#143352] font-normal ml-3 space-y-2 font-['Source_Sans_Pro']">
      {included.map((item: { name: string; size: string; isGift: boolean }) => (
        <>
          <li className="items-center">
            {item.isGift && (
              <div className="inline-block text-[#143352] mr-2 py-0.5 px-1.5 rounded bg-blue-300 bg-opacity-30 relative items-center border border-blue-200 text-[13px]">
                <GiftIcon className="h-4 w-4 absolute mt-0.5" />
                <p className="ml-5" children="Regalo" />
              </div>
            )}
            {item.name}
            <span className="ml-1.5 text-[#2e73b8] opacity-50">{item.size}</span>
          </li>
        </>
      ))}
    </ul>
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      included {
        name
        size
        isGift
      }
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => <HeroPackItems included={data.productsYaml.included} />}
  />
);
