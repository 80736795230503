import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

interface IHeroImageGalleryProps {
  productImages: Array<{
    img: ImageDataLike;
    alt: string;
  }>;
}
const HeroImageGallery = ({ productImages }: IHeroImageGalleryProps) => {
  const imgNumber = productImages.length;
  const [currentIdx, setCurrentIdx] = React.useState(0);
  return (
    <div className="grid sm:grid-cols-9 gap-3 mx-5 sm:mx-0">
      {/* SmallImagesColumn - Start */}
      <div className="col-span-1 hidden w-full h-full sm:block">
        <div className={`grid grid-rows-${imgNumber} gap-3 h-full`}>
          {productImages.map((image, idx) => (
            <div onClick={() => setCurrentIdx(idx)} className="relative">
              <div className="absolute w-full h-full hover:bg-white hover:opacity-30 z-10" />
              <GatsbyImage
                key={idx}
                alt={image.alt}
                image={getImage(image.img)!}
                className="aspect-square"
              />
            </div>
          ))}
        </div>
      </div>
      {/* SmallImagesColumn - End */}

      {/* BigImage - Start */}
      <div className="col-span-8 relative z-0">
        <div
          className={`${
            currentIdx == 7 && "hidden"
          } absolute w-14 bg-black bg-opacity-5 hover:bg-opacity-10 h-full flex flex-wrap content-center justify-center  z-10 right-0`}
          onClick={() => setCurrentIdx(currentIdx + 1)}
          children={<ChevronRightIcon className="h-8" />}
        />
        <div
          className={`${
            currentIdx == 0 && "hidden"
          } absolute w-14 bg-black bg-opacity-5 hover:bg-opacity-10 h-full flex flex-wrap content-center justify-center z-10 left-0`}
          onClick={() => setCurrentIdx(currentIdx - 1)}
          children={<ChevronLeftIcon className="h-8" />}
        />
        {productImages.map((image, idx) => (
          <div className={`${currentIdx == idx ? "" : "hidden"}`}>
            <GatsbyImage
              key={idx}
              alt={image.alt}
              image={getImage(image.img)!}
              className="aspect-square"
            />
          </div>
        ))}
      </div>
      {/* BigImage - End */}
    </div>
  );
};

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      images {
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        alt
      }
    }
  }
`;

export default () => (
  <StaticQuery
    query={query}
    render={(data) => <HeroImageGallery productImages={data.productsYaml.images} />}
  />
);
