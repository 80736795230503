import * as React from "react";
import HeroImageGallery from "components/MiniVSL/ProductHero/HeroImageGallery";
import HeroProductDetails from "components/MiniVSL/ProductHero/HeroProductDetails";

export default () => (
  <div className="grid sm:grid-cols-8 gap-8 mt-5 sm:mt-14">
    <div className="sm:col-span-5">
      <HeroImageGallery />
    </div>
    <div className="sm:col-span-3 mx-5 sm:mx-0 overflow-visible">
      <HeroProductDetails />
    </div>
  </div>
);
