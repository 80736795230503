import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

interface IProductBenefitsProps {
  benefitsImageAlt: string;
  benefitsImage: ImageDataLike;
  benefits: Array<{
    icon: string;
    title: string;
  }>;
}
const ProductBenefits = ({ benefits, benefitsImage, benefitsImageAlt }: IProductBenefitsProps) => (
  <div className="grid sm:grid-cols-10 gap-8 my-20 mx-5 sm:mx-0">
    <div className="sm:mr-5 sm:col-span-4 ">
      <GatsbyImage
        className="aspect-square"
        alt={benefitsImageAlt}
        image={getImage(benefitsImage)!}
      />
    </div>
    <div className="sm:col-span-6">
      <h1 className="text-2xl font-bold text-planasDark font-[Montserrat]">
        Propiedades y Beneficios
      </h1>
      <ul className="text-base font-normal my-5 opacity-80 divide-y divide-gray-300  font-['Source_Sans_Pro'] text-[17px]">
        {benefits.map((benefit, idx) => (
          <li key={idx} className="py-1.5 items-center align-middle">
            <div className="ml-1 flex items-center">
              {benefit.icon == "check" ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <XIcon className="h-4 w-4" />
              )}
              <span className="ml-2 font-['Source_Sans_Pro]">{benefit.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const query = graphql`
  query {
    productsYaml(slug: { eq: "pack-antiaging" }) {
      benefits {
        icon
        title
      }
      benefitsImageAlt
      benefitsImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <ProductBenefits
        benefits={data.productsYaml.benefits}
        benefitsImage={data.productsYaml.benefitsImage}
        benefitsImageAlt={data.productsYaml.benefitsImageAlt}
      />
    )}
  />
);
